import React from 'react'
import WhiteLogo from '../../assets/images/logo-white.png';
import { NavLink } from "react-router-dom";
function Footer() {
    return (
        <>
             <footer>
            <div className="container">
                <a className="footerLogo">
                    <img src={WhiteLogo} alt="" />
                    <span>Phoenixstraat 54, Delft The Netherlands</span>
                </a>
                <ul className="menu">
                    <li><a>Contact us</a></li>
                    <li><a>Visit untamed isles</a></li>
                </ul>
                <ul className="social">
                    <li><a></a></li>
                    <li><a></a></li>
                    <li><a></a></li>
                </ul>
            </div>
        </footer>
        </>
    )
}

export default Footer
