
import './App.scss';
import Home from "./Home/Home";
import Investment from "./Investment/Investment";
import Header from "./Shared/Header/Header";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from './Shared/Footer/Footer';
import Recruitment from './Recruitment/Recruitment';

function App() {
  return (
    <div className="pgWrapperOuter">
      <Router>
        <Header/>
        <Switch>
          <Route component={Home} path="/" exact />
          <Route component={Investment} path="/investment" />
          <Route component={Recruitment} path="/recruitment" />
        </Switch>
       <Footer/>
      </Router>
    </div>
  );
}

export default App;
