import './Home.scss';
import { NavLink } from "react-router-dom";
import Logo from '../assets/images/logo.png';
import Anime from '../assets/images/anime1.png';
import Invest from '../assets/images/investment.png';
import Recruit from '../assets/images/recruiment.png';
import Avatar from '../assets/images/avatar.png';
import Linkedin from '../assets/images/linkedin.png';
import WhiteLogo from '../assets/images/logo-white.png';
import {Link} from 'react-scroll'

function Home() {
   
  return (
    <div className="home">
       
        <div className="section1" id="about">
            <div className="container">
                <h2>bringing together developers and gaming in the crypto space</h2>
                <p className="subText">NFT & gaming (secondary header here)</p>
                <div className="content">
                    <div className="left">
                        <h3>
                            <span>phat loot</span> studios
                        </h3>
                        <p className="subText">Up and coming game<br/> studio in the play-to-earn market. </p>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                        <p>Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. </p>
                        <p>Links and highlighted text are in <a>this color.</a></p>
                    </div>
                    <div className="right">
                    <h3>
                            <span>phat loot</span> DeFi
                        </h3>
                        <p className="subText">Exclusive partner for<br/> blockchain related technology.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                        <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="section2"  id="technologies">
            <div className="container">
                <h2>technologies</h2>
                <div className="content">
                    <div className="left">
                        <h3>phat loot token</h3>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. </p>
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                    </div>
                    <div className="right">
                        <h3>nft marketplace</h3>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. </p>
                        <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                        <p>Also the leap into electronic typesetting, remaining essentially unchanged. </p>
                    </div>
                </div>
                <div className="anime">
                    <img src={Anime} alt="" />
                    <hr/>
                </div>
                <div className="content">
                    <div className="left">
                        <h3>blockchain development</h3>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. </p>
                        <p>Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur.</p>
                    </div>
                    <div className="right">
                        <h3>nft collections</h3>
                        <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                        <p>Also the leap into electronic typesetting, remaining essentially unchanged. To see Guppy Gang go <a>here.</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div className="section3">
            <div className="container">
                <img src={Invest} alt="" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
        </div>

        <div className="section3">
            <div className="container">
                <img src={Recruit} alt="" />
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.</p>
                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. </p>
                <a className="contactBtn">Contact</a>
            </div>
        </div>

        <div className="section4" id="team">
            <div className="container">
                <h2>the TEAM</h2>
                <p className="subText">Team statement or secondary header text here.</p>
                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC.</p>
                <ul className="testimonial">
                    <li>
                        <div className="avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <h3>Name Here</h3>
                        <p className="subText"><span>Title here</span> <a><img src={Linkedin} alt="" /></a></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </li>
                    <li>
                        <div className="avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <h3>Name Here</h3>
                        <p className="subText"><span>Title here</span> <a><img src={Linkedin} alt="" /></a></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </li>
                    <li>
                        <div className="avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <h3>Name Here</h3>
                        <p className="subText"><span>Title here</span> <a><img src={Linkedin} alt="" /></a></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </li>
                    <li>
                        <div className="avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <h3>Name Here</h3>
                        <p className="subText"><span>Title here</span> <a><img src={Linkedin} alt="" /></a></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </li>
                    <li>
                        <div className="avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <h3>Name Here</h3>
                        <p className="subText"><span>Title here</span> <a><img src={Linkedin} alt="" /></a></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </li>
                    <li>
                        <div className="avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <h3>Name Here</h3>
                        <p className="subText"><span>Title here</span> <a><img src={Linkedin} alt="" /></a></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </li>
                    <li>
                        <div className="avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <h3>Name Here</h3>
                        <p className="subText"><span>Title here</span> <a><img src={Linkedin} alt="" /></a></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </li>
                    <li>
                        <div className="avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <h3>Name Here</h3>
                        <p className="subText"><span>Title here</span> <a><img src={Linkedin} alt="" /></a></p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    </li>
                </ul>
            </div>
        </div>

       
    </div>
  );
}

export default Home;